import Vue from 'vue'
import PlacesAutocomplete from '../places_autocomplete/places_autocomplete.vue.erb'

document.addEventListener('turbolinks:load', () => {
  const autocompletes = document.getElementsByClassName('places-autocomplete')
  Array.prototype.forEach.call(autocompletes, function (autocomplete) {
    window.placesAutocomplete = new Vue({
      el: autocomplete,
      propsData: {
        name: autocomplete.name,
        initialValue: autocomplete.value,
        placeholder: autocomplete.placeholder,
      },
      ...PlacesAutocomplete,
    })
  })
})
