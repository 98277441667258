<script>
import { jsonRequestHeader } from '../shared/headers'
import { ModalMixin } from '../shared/modal_mixin'

export default {
  mixins: [ModalMixin],
  props: {
    userPath: { type: String, required: true }
  },
  data () {
    return {
      modalViewState: 'closed',
      engagement: false,
      platform_updates: false,
      error: null
    }
  },
  computed: {
    isValid () {
      return this.engagement || this.platform_updates
    }
  },
  methods: {
    destroyModal () {
        this.saveUser()
        this.reloadPage()
    },
    toggleEngagement () {
      this.engagement = !this.engagement
    },
    togglePlatformUpdates () {
      this.platform_updates = !this.platform_updates
    },
    saveClick () {
      if (this.isValid) {
        this.saveUser()
      }
    },
    saveUser () {
      let timestamp = new Date()
      let requestBody = {
        user: {
          is_reconsent_shown: true,
          engagement_email_consent: this.engagement ? timestamp : null,
          platform_email_consent: this.platform_updates ? timestamp : null
        }
      }

      this.$http.put(this.userPath, requestBody, jsonRequestHeader).then(response => {
          if (response.status !== 200) {
            throw new Error(response.body.message)
          }

          this.closeModal()
          this.reloadPage()
      }, error => {
          const errorMessage = error.body.message || error.body.error
          this.error = errorMessage
          throw new Error(errorMessage)
      })
    },
    reloadPage () {
      // if user selected any mailings, reload page to refresh
      if (this.isValid) {
        location.reload()
      }
    }
  }
}
</script>

<template>
  <div class="modal__container no-click-tracking" :class="modalViewState" id="consent-modal" ref="modalContainer">
    <div class="modal__form-container" ref="modalFormContainer">
      <div id="close-consent-modal" class="modal__close-button" data-modal-id="consent-modal" :title="translations.close" @click="destroyModal">
        <img src="/assets/icons/close_white-55a0f9afd57a1c1d72faab83967e2283a6ec0f7d8b1d57fa412a1760faa9b4f4.svg" />
      </div>

      <br/>

      <div class="modal__heading">
        {{ translations.consent.modal.heading }}
      </div>

      <div class="modal__consent-text">
        {{ translations.consent.modal.explanation }}
      </div>

      <div class="modal__consent-checkboxes">
        <div id="toggle-engagement-consent-modal" class="modal__consent-checkbox" @click="toggleEngagement">
          <input class="fancy-checkbox" name="engagement_email" type="checkbox" :value="1" :checked="this.engagement" id="engagement_email_checkbox" />
          <label class="u-inline-block" for="engagement_email">
            {{ translations.activerecord.attributes.user.engagement_email_consent }}
          </label>
        </div>

        <div id="toggle-platform-updates-consent-modal" class="modal__consent-checkbox" @click="togglePlatformUpdates">
          <input class="fancy-checkbox" name="platform_updates_email" type="checkbox" :value="1" :checked="this.platform_updates" id="platform_updates_email_checkbox" />
          <label class="u-inline-block" for="platform_updates_email">
            {{ translations.activerecord.attributes.user.platform_email_consent }}
          </label>
        </div>
      </div>

      <div id="save-consent-modal"
          class="modal__save-button big-button"
          tabindex="3"
          :class="{ 'big-button--disabled': !isValid }"
          @click="saveClick"
          @keyup.enter="saveClick">
        {{ translations.consent.modal.save_button }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/variables.scss';
  @import '../styles/modal.scss.erb';

  .modal__consent-checkboxes {
    margin: 50px 0;
  }
</style>