<script>
export default {
  props: {
    notificationsPath: { type: String, required: true }
  },
  data () {
    return {
      menuViewState: 'closed',
      notifications: null,
      isLoading: true,
      error: null,
    }
  },
  mounted () {
    this.positionMenu()

    window.addEventListener('resize', this.positionMenu)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.positionMenu)
  },
  computed: {
      translations () {
          return window.I18n
      },
  },
  methods: {
    loadNotifications () {
      let promises = []
      promises.push(this.$http.get(this.notificationsPath))
      // wait for at least 250ms to avoid loader flicker
      promises.push(new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve()
        }, 500)
      }))

      return Promise.all(promises)
    },
    openMenu () {
      this.menuViewState = 'open'

      this.loadNotifications().then(response => {
        this.notifications = response[0].body
        this.isLoading = false
        this.$nextTick(() => this.positionMenu())
      }, error => {
        const errorMessage = error.body.message || error.body.error
        this.isLoading = false
        this.error = errorMessage
        throw new Error(errorMessage)
      })
    },
    closeMenu () {
      this.menuViewState = 'closed'
      this.notifications = null
      this.isLoading = true
      this.error = null
    },
    positionMenu () {
      // fix alignment of notification menu container
      // we have to do this with JS since name in header is of arbitrary length
      // but only for large widths where name is shown
      const clientWidth = document.documentElement.clientWidth
      if (clientWidth >= 1200) {
        const offsetLeft = document.getElementById('header-notifications-button-icon').getBoundingClientRect().left + 15 // + 15 for half the width of notification icon
        var menuContainerWidth
        if (this.$refs.menuContainer.getBoundingClientRect().width) {
            menuContainerWidth = this.$refs.menuContainer.getBoundingClientRect().width
        } else {
            menuContainerWidth = 450
        }

        const menuTickRightOffset = 44
        const offsetRight = clientWidth - offsetLeft - menuTickRightOffset
        this.$refs.menuContainer.style.right = `${offsetRight}px`
      }
    }
  }
}
</script>

<template>
  <div class="header__menu header__menu--notifications-menu menu__container no-click-tracking" :class="menuViewState" id="notifications-menu" ref="menuContainer" v-click-outside="closeMenu">
    <div class="menu__content-wrapper">
      <div class="menu__loader" v-if="isLoading">
        <div class="menu__notifications">
          <div class="menu__notification menu__notification--loader" v-for="index in 4" :key="index">
            <div class="menu__notification-icon menu__notification-part-loader menu__notification-icon--loader"></div>
            <div class="menu__notification-content">
              <div class="menu__notification-title menu__notification-part-loader menu__notification-title--loader"></div>
              <div class="menu__notification-body menu__notification-part-loader menu__notification-body--loader"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="menu__content" v-else>
        <div class="menu__notifications" :class="{ 'menu__notifications--empty': notifications.length === 0 }">
          <template v-if="notifications.length > 0" v-for="notification in notifications">
            <a :href="notification.link" class="menu__notification" :class="{ 'menu__notification--unread': !notification.is_read }" :target="notification.notification_type === 'advertising' ? '_blank' : ''" v-if="notification.link">
              <div class="menu__notification-icon" :class="`menu__notification-icon--${notification.for_type}`" :style="`background-image: url(${notification.icon_url});`"></div>
              <div class="menu__notification-content">
                <div class="menu__notification-title">{{ notification.title }}</div>
                <div class="menu__notification-body">{{ notification.body }}</div>
                <div class="menu__notification-timestamp">{{ notification.publish_time }}</div>
              </div>
            </a>

            <div class="menu__notification" :class="{ 'menu__notification--unread': !notification.is_read }" v-else>
              <div class="menu__notification-icon" :class="`menu__notification-icon--${notification.for_type}`" :style="`background-image: url(${notification.icon_url});`"></div>
              <div class="menu__notification-content">
                <div class="menu__notification-title">{{ notification.title }}</div>
                <div class="menu__notification-body">{{ notification.body }}</div>
                <div class="menu__notification-timestamp">{{ notification.publish_time }}</div>
              </div>
            </div>
          </template>

          <template v-else>
            <div>
              {{ translations.notifications.no_notifications }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/stylesheets/variables.scss';

.menu__container {
  @include respond-to(small) {
    min-width: 200px;
    max-width: 75%;
  }

  @include respond-to(medium) {
    min-width: 300px;
    max-width: 60%;
  }

  @include respond-to(large) {
    min-width: 450px;
    max-width: 500px;
  }
}

.menu__container.closed {
  display: none;
}

.menu__container.open {
  display: block;
}

.menu__loader {
  margin-bottom: -8px;
}

.menu__loader > img {
  width: 50px;
}

.menu__notifications {
  overflow-y: auto;

  @include respond-to(small) {
    max-height: 300px;
  }

  @include respond-to(medium) {
    max-height: 400px;
  }

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.menu__notifications--empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50px;
  padding: 10px;
}

.menu__notification {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid $border_gray;
  text-align: left;

  &:hover {
    background-color: $notifications_hover_grey;
  }

  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 6px 6px;
  }
}

.menu__notification--unread {
  background-color: $notifications_unread_blue;

  &:hover {
    background-color: $notifications_unread_hover_blue;
  }
}

.menu__notification--loader {
  height: 55px;

  &:hover {
    background-color: transparent;
  }
}

.menu__notification-part-loader {
  &:after {
    width: 100%;
    content: "";
    position: absolute;
    animation: PLACEHOLDER_SHIMMER 1s linear infinite forwards;
    background: linear-gradient(to right, $notifications_hover_grey 8%, #ddd 18%, $notifications_hover_grey 33%);
    background-size: 5000px 10px;
  }
}

.menu__notification-content {
  width: 100%;
  position: relative;
  margin-bottom: 3px;
}

.menu__notification-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  flex: 0 0 40px;
  margin-right: 5px;

  -webkit-background-size: 70%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}

.menu__notification-icon--custom {
  -webkit-background-size: 100%;
  background-size: 100%;
}

.menu__notification-icon--travel {
  background-color: $main_green;
}

.menu__notification-icon--sport {
  background-color: $main_yellow;
}

.menu__notification-icon--culture {
  background-color: $main_red;
}

.menu__notification-icon--lease {
  background-color: $main_gray;
}

.menu__notification-icon--loader {
  width: 40px;
  height: 40px;
  vertical-align: top;

  &:after {
    width: 40px;
    height: 40px;
    border-radius: 100%;

    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
   }
}

.menu__notification-title {
  margin-left: 5px;
  color: black;
  font-weight: bold;
}

.menu__notification-title--loader {
  height: 30px;

  &:after {
    width: 75px;
    height: 13px;
    margin-top: 5px;
  }
}

.menu__notification-body {
  margin-left: 5px;
  color: black;
}

.menu__notification-timestamp {
  position: absolute;
  right: 0;
  bottom: -11px;
  text-align: right;
  color: $gray_text;
  font-size: 11px;
}

.menu__notification-body--loader {
  height: 30px;

  &:after {
    height: 13px;

    @include respond-to(small) {
      width: 140px;
    }

    @include respond-to(medium) {
      width: 225px;
    }

    @include respond-to(large) {
      width: 370px;
    }
  }
}

@keyframes PLACEHOLDER_SHIMMER {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
</style>