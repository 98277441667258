<script>
import { debounce } from 'lodash'

export default {
  props: {
    name: { type: String, required: true },
    initialValue: { type: String, required: true },
    placeholder: { type: String, required: true },
  },
  data () {
    return {
      error: null,
      isDropdownOpen: false,
      isDropdownLoading: false,
      isSubmitDisabled: false,
      isItemSelected: false,
      dropdownItems: [],
      timeout: null,
      value: this.initialValue,
    }
  },
  beforeCreate () {
    this.baseApiUrl = 'https://autocomplete.search.hereapi.com/v1/autocomplete?apiKey=JmXFaPyzxivHenHJPRoYok79XQyf8_m2WUeRlhe6aUM&limit=10'
  },
  mounted () {
    this.$refs.input.addEventListener('focus', this.trickChromeSuggestions)
    this.$refs.input.addEventListener('blur', this.revertChromeSuggestionsTrick)
  },
  beforeDestroy () {
    this.$refs.input.removeEventListener('focus', this.trickChromeSuggestions)
    this.$refs.input.removeEventListener('blur', this.revertChromeSuggestionsTrick)
  },
  computed: {
    translations () {
      return window.I18n
    },
    apiUrl () {
      return `${this.baseApiUrl}&lang=${window.I18n.locale_id}&q=`
    },
    isInputPresent () {
      return this.value.length > 0
    },
    showNoResults () {
      return this.dropdownItems.length === 0 && this.isInputPresent && !this.showError
    },
    showEnterFirstChar () {
      return this.dropdownItems.length === 0 && !this.isInputPresent && !this.showError
    },
    showError () {
      return this.error != null
    },
  },
  methods: {
    setValue () {
      this.value =  this.$refs.input.value
      this.debouncedSearchPlaces()
    },
    debouncedSearchPlaces: debounce(function () {
      this.searchPlaces()
    }, 250),
    searchPlaces () {
      debugger
      const vueInstance = this
      const search = function () {
        if (this.isInputPresent) {
          this.openDropdown()
          this.runSearchQuery(this.value)
        } else {
          this.closeDropdown()
        }
      }

      const clearTimeout = function () {
        vueInstance.timeout = null
      }

      if (!this.timeout) {
        search.apply(this)
      }
      this.timeout = setTimeout(clearTimeout, 250)

      this.setSubmitButtonsDisabled(true)
    },
    runSearchQuery (input) {
      this.error = null
      this.$http.get(`${this.apiUrl}${input}`).then((response) => {
        const body = response.body
        if (body && body.items) {
          this.dropdownItems = body.items.map(item =>  item.address.label)
        } else {
          this.error = 'No items in response'
          console.error(`No items in response: ${JSON.stringify(body)}`)
        }
      }).catch((error) => {
        this.error = error
        console.error(error)
      }).finally(() => {
        setTimeout(() => {
          this.isDropdownLoading = false
        }, 250)
      })
    },
    selectItem (item) {
      this.isItemSelected = true
      this.value = item
      this.isDropdownOpen = false

      this.revertChromeSuggestionsTrick()
      this.setSubmitButtonsDisabled(false)
    },
    openDropdown () {
      this.trickChromeSuggestions()
      this.isDropdownLoading = true
      this.isDropdownOpen = true
      this.isItemSelected = false
    },
    closeDropdown () {
      this.isDropdownOpen = false
      this.dropdownItems = []

      if (this.isSubmitDisabled && this.isInputPresent) {
        this.displayInvalidLocationAlert()
      }

      this.revertChromeSuggestionsTrick()
    },
    setSubmitButtonsDisabled (isDisabled) {
      this.isSubmitDisabled = isDisabled
      const title = isDisabled ? this.translations.messages.posts.location_invalid : ''

      // very very ugly :/ but since I know it's ugly I can do it, I guess :)
      if (document.getElementById('submit-post-button')) {
        document.getElementById('submit-post-button').disabled = isDisabled
        document.getElementById('submit-post-button').parentElement.title = title
      }
      if (document.getElementById('change-user')) {
        document.getElementById('change-user').disabled = isDisabled
        document.getElementById('change-user').parentElement.title = title
      }
    },
    displayInvalidLocationAlert () {
      alert(I18n.messages.posts.location_not_selected)
    },
    trickChromeSuggestions () {
      this.$refs.input.name = Math.random().toString(36);
    },
    revertChromeSuggestionsTrick () {
      this.$refs.input.name = this.name
    },
  },
}
</script>

<template>
  <div class="places-autocomplete">
    <input type="text" name="locationremembered" style="display: none">
    <input class="places-autocomplete__input full-page-modal__input" type="text" spellcheck="false" autocomplete="new-password" :name="name" :value="value" :placeholder="placeholder" @input="setValue" @click="debouncedSearchPlaces" ref="input">

    <div class="places-autocomplete__dropdown" v-if="isDropdownOpen" v-click-outside="closeDropdown">
      <div class="places-autocomplete__dropdown-loader" v-if="isDropdownLoading">
        <img src="/assets/icons/loader-5cd597c3ca70a2e7c54d37fb8ef80a05ce6ca347b1cbad009a4ea1f9f6fe399a.svg" />
      </div>

      <template v-else>
        <div class="places-autocomplete__dropdown-empty" v-if="showError">{{ translations.error }}</div>
        <div class="places-autocomplete__dropdown-empty" v-else-if="showNoResults">{{ translations.input_empty }}</div>
        <div class="places-autocomplete__dropdown-empty" v-else-if="showEnterFirstChar">{{ translations.no_results }}</div>
        <div class="places-autocomplete__dropdown-item" v-else v-for="item in dropdownItems" @click="selectItem(item)">{{ item }}</div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/stylesheets/variables.scss';

.places-autocomplete {
  position: relative;
}

.places-autocomplete__dropdown {
  position: absolute;
  top: 65px;
  width: calc(100% - 2px);
  background-color: white;
  border: 1px solid $form_element_border;
  border-radius: 4px;
  z-index: $z_index_overlay;
  box-shadow: 0 0 10px 5px whitesmoke;
}

.places-autocomplete__dropdown-loader {
  padding: 20px 0;
  text-align: center;
}

.places-autocomplete__dropdown-empty {
  text-align: center;
  padding: 10px 0;
  color: $error_red;
}

.places-autocomplete__dropdown-item {
  padding: 6px 10px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: $link_blue;
  }
}
</style>
