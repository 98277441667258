/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
//

import './shop_dialog'
import './report_dialog'
import './consent_dialog'
import './notification_menu'
import './places_autocomplete'

import Vue from 'vue'
import VueResource from 'vue-resource'

import '../shared/vue_helpers'

Vue.use(VueResource)
