export const ModalMixin = {
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('keyup', this.keyUpEventListener)

      this.setModalHeight()
      this.openModal()
    })
  },
  beforeDestroy () {
    // remove global event listeners
    window.removeEventListener('keyup', this.keyUpEventListener)
  },
  computed: {
    translations () {
      return window.I18n
    },
  },
  methods: {
    positionModal () {
      // set modal offset to be always visible in scroll
      const scrollTop = window.scrollY
      this.$refs.modalFormContainer.style.top = `${scrollTop}px`
    },
    setModalHeight () {
      // set modal height to fit the page
      const currentHeight = this.$el.clientHeight
      const body = document.body
      const html = document.documentElement
      const height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

      if (currentHeight < height) {
        this.$el.style.height = `${height}px`
      }
    },
    keyUpEventListener (event) {
      if (event.keyCode === 27) {
        event.preventDefault()
        event.stopPropagation()
        this.closeModal()
      }
    },
    openModal () {
      this.positionModal()

      if (this.modalViewState !== 'opening' && this.modalViewState !== 'open') {
        this.modalViewState = 'opening'
      }

      const modalContainer = this.$refs.modalContainer
      const onOpeningAnimationEnd = (ev) => {
        this.modalViewState = 'open'
        ev.target.removeEventListener('animationend', onOpeningAnimationEnd)
      }

      modalContainer.addEventListener('animationend', onOpeningAnimationEnd)
    },
    closeModal () {
      this.modalViewState = 'closing'

      const modalContainer = this.$refs.modalContainer
      const onClosingAnimationEnd = (ev) => {
        this.modalViewState = 'closed'
        ev.target.removeEventListener('animationend', onClosingAnimationEnd)
      }

      modalContainer.addEventListener('animationend', onClosingAnimationEnd)
    },
  },
}
