<script>
import { jsonRequestHeader } from '../shared/headers'
import { ModalMixin } from '../shared/modal_mixin'

export default {
  mixins: [ModalMixin],
  props: {
    reportPath: { type: String, required: true },
    banPath: { type: String, required: false }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.messageInput.focus()
      }, 50)
    })
  },
  data () {
    return {
      modalViewState: 'closed',
      message: '',
      isSendButtonClicked: false,
      isReportSent: false,
      isUserBanned: false,
      error: null
    }
  },
  computed: {
    isValid () {
      return this.message !== ''
    }
  },
  methods: {
    destroyModal () {
      this.closeModal()

      // if user banned, reload conversations
      if (this.isUserBanned) {
        location.reload()
      }

      this.message = ''
      this.isReportSent = false
      this.isUserBanned = false
      this.isSendButtonClicked = false
      this.error = null
    },
    report () {
      this.isSendButtonClicked = true

      if (this.isValid) {
        let requestBody = { message: this.message }
        this.isReportSent = true

        this.$http.post(this.reportPath, requestBody, jsonRequestHeader).then(response => {
          if (response.status !== 200) {
            throw new Error(response.body.message)
          }
        }, error => {
          let errorMessage = error.body.message || error.body.error
          this.error = errorMessage
          throw new Error(errorMessage)
        })
      }
    },
    ban () {
      if (this.banPath && confirm(this.translations.report.modal.ban_confirmation)) {
        this.isUserBanned = true

        this.$http.put(this.banPath, {}, jsonRequestHeader).then(response => {
          if (response.status !== 204) {
            throw new Error(response.body.message)
          }
        }, error => {
          let errorMessage = error.body.message || error.body.error
          this.error = errorMessage
          throw new Error(errorMessage)
        })
      }
    }
  }
}
</script>

<template>
  <div class="modal__container no-click-tracking" :class="modalViewState" id="report-modal" ref="modalContainer">
    <div class="modal__form-container" ref="modalFormContainer">
      <div id="close-report-modal" class="modal__close-button" data-modal-id="report-modal" :title="translations.close" @click="destroyModal">
        <img src="/assets/icons/close_white-55a0f9afd57a1c1d72faab83967e2283a6ec0f7d8b1d57fa412a1760faa9b4f4.svg" />
      </div>

      <br/>

      <div class="modal__heading">
        {{ translations.report.modal.heading }}
      </div>

      <div class="modal__final" v-if="isReportSent || isUserBanned">
        <div class="modal__final-error" v-if="error">
          <div class="modal__final-icon-container">
            <img src="/assets/icons/error_red-d4e8e8fe757c8077d3f5f5587e961a0013151eec44a443330b54decbccaee905.svg" />
          </div>

          <div class="modal__final-text modal__final-text--error">
            {{ translations.report.modal.error }}
          </div>
        </div>

        <div class="modal__final-success" v-else>
          <div v-if="isUserBanned">
            <div class="modal__final-text">
              {{ translations.report.modal.user_banned }}
            </div>
          </div>

          <div v-else>
            <div class="modal__final-icon-container">
              <img src="/assets/icons/flying_email-41122fc58e6dec8e3542280f17721e14b59f68cf8625eb79b8f54f1ef0722f2f.svg" />
            </div>

            <div class="modal__final-text">
              {{ translations.report.modal.success }}
            </div>

            <div class="modal__ban-user" v-if="banPath">
              <div class="modal__ban-user-text">
                {{ translations.report.modal.ban_user }}
              </div>

              <div id="ban-user-after-report-report-modal" class="modal__ban-user-button big-button" @click="ban">
                {{ translations.report.modal.ban_user_button }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal__form" v-else>
        <div class="modal__tab-content-container modal__tab-content-container--active">
          <div class="conditional-message__form-field">
            <label class="modal__label">{{ translations.report.modal.message }}<span class='required-asterix'>*</span></label>
            <input name="message" v-model="message" :placeholder="translations.report.modal.message_placeholder" class="modal__input-field" :class="(isSendButtonClicked && !isValid) ? 'modal__input-field--error' : ''" ref="messageInput" tabindex="1" />
          </div>
        </div>

        <div
            id="report-user-report-modal"
            class="modal__report-button big-button"
            tabindex="2"
            :class="{ 'big-button--disabled': !isValid }"
            :title="isValid ? '' : translations.report.modal.disabled_tooltop"
            @click="report"
            @keyup.enter="report">
          {{ translations.report.modal.report }}
        </div>

        <template v-if="banPath">
          <div class="u-horizontal-align-center u-bold u-uppercase">
            {{ translations.or }}
          </div>

          <div
              id="ban-user-report-modal"
              class="modal__ban-button big-button"
              v-if="banPath"
              tabindex="3"
              @click="ban"
              @keyup.enter="ban">
            {{ translations.report.modal.ban_user_only_button }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/variables.scss';
  @import '../styles/modal.scss.erb';

  .modal__form-container {
    position: relative;
  }

  .modal__input-field {
    padding: 0 5px;
  }

  .modal__input-field--error {
      border: 1px solid $error_red;
  }

  .modal__ban-user {
    margin-top: 50px;
    text-align: center;
  }
</style>
