import Vue from 'vue'
import NotificationMenu from '../notification_menu/notification_menu.vue.erb'

const clearUnreadCounter = function () {
  const unreadCounter = document.getElementById('unread-notifications-counter')
  if (unreadCounter) {
    unreadCounter.style.display = 'none'
  }
}

document.addEventListener('turbolinks:load', () => {
  if (window.current_user) {
    const notificationButton = document.getElementById('header-notifications-button')
    if (notificationButton && !notificationButton.dataset.jsProcessed) {
      notificationButton.addEventListener('click', function (ev) {
        // prevent instant trigger of click-outside Vue directive
        ev.stopPropagation()
        ev.preventDefault()

        if (window.notificationMenu && document.getElementById('notifications-menu')) {
          if (window.notificationMenu.menuViewState === 'closed') {
            window.notificationMenu.openMenu()
            clearUnreadCounter()
          } else {
            window.notificationMenu.closeMenu()
          }
        } else {
          const notificationsPath = '/notifications/mine.json'
          document.getElementById('header-notification-menu').appendChild(document.createElement('notification-menu'))

          window.notificationMenu = new Vue({ propsData: {
            notificationsPath: notificationsPath,
          },
          ...NotificationMenu,
          })
          window.notificationMenu.$mount('notification-menu')
          window.notificationMenu.openMenu()
          clearUnreadCounter()
        }
      })

      notificationButton.dataset.jsProcessed = true
    }
  }
})

