import Vue from 'vue'
import ReportDialog from '../report_dialog/report_dialog.vue.erb'

document.addEventListener('turbolinks:load', () => {
  const reportButtons = document.getElementsByClassName('report-button')
  for (var i=0; i < reportButtons.length; i++) {
    reportButtons[i].addEventListener('click', (ev) => {
      const reportPath = ev.target.getAttribute('report-path') ? ev.target.getAttribute('report-path') : ev.target.parentElement.getAttribute('report-path')
      const banPath = ev.target.getAttribute('ban-path') ? ev.target.getAttribute('ban-path') : ev.target.parentElement.getAttribute('ban-path')

      if (!document.getElementById('report-modal')) {
        document.getElementById('modals-container').appendChild(document.createElement('report-dialog'))
        window.reportDialog = new Vue({ propsData: {
          reportPath: reportPath,
          banPath: banPath,
        },
        ...ReportDialog,
        })
        window.reportDialog.$mount('report-dialog')
      } else {
        window.reportDialog.openModal()
      }
    })
  }
})
