import Vue from 'vue'
import ConsentDialog from '../consent_dialog/consent_dialog.vue.erb'

document.addEventListener('turbolinks:load', () => {
  if (window.current_user && !window.current_user.is_reconsent_shown) {
    const userPath = '/users/register'

    if (!document.getElementById('consent-modal')) {
      document.getElementById('modals-container').appendChild(document.createElement('consent-dialog'))
      window.consentDialog = new Vue({ propsData: {
        userPath: userPath,
      },
      ...ConsentDialog,
      })
      window.consentDialog.$mount('consent-dialog')
    } else {
      window.consentDialog.openModal()
    }
  }
})
