import Vue from 'vue'
import ShopDialog from '../shop_dialog/shop_dialog.vue.erb'

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('shop-container')) {
    const shopButtons = document.getElementsByClassName('shop__buy-button')
    for (var i=0; i < shopButtons.length; i++) {
      shopButtons[i].addEventListener('click', () => {
        if (!document.getElementById('shop-modal')) {
          document.getElementById('modals-container').appendChild(document.createElement('shop-dialog'))
          window.shopDialog = new Vue({ propsData: {
            currentUser: window.current_user,
          },
          ...ShopDialog,
          })
          window.shopDialog.$mount('shop-dialog')
        } else {
          window.shopDialog.openModal()
        }
      })
    }
  }
})
